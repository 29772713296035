<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template
      v-if="userData"
      #button-content
    >
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.un }}
        </p>
        <span class="user-status">{{ userData.ro === '0' ? 'Admin' : userData.ro === '1' ? 'Supervisor' : 'Only View' }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        :text="userData.un.charAt(0)"
        class="badge-minimal"
        :badge-variant=" onLine ? `success` : `danger`"
      >
        <feather-icon
          v-if="!userData.un"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="goToGeneralSetting"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>General setting</span>
    </b-dropdown-item>
    <hr>
    <b-dropdown-item
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem,
  // BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
// import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
      onLine: navigator.onLine,
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.AppActiveUser
    },
  },
  mounted() {
    window.addEventListener('online', () => { this.onLine = true })
    window.addEventListener('offline', () => { this.onLine = false })
  },
  methods: {
    goToGeneralSetting() {
      this.$router.push({ name: 'setting' })
    },
    logout() {
      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$store.dispatch('auth/logoutUser')
    },
    updateOnlineStatus(e) {
      const {
        type,
      } = e
      this.onLine = type === 'online'
    },
  },
}
</script>
