<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex cursor-pointer"
      style=""
      @click="goToHome"
    >

      <Logo />
      <h3 class="brand-text ml-1  mb-0">
        Yene Property
      </h3>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item @click="changeLocalLang">
        <feather-icon
          size="21"
          icon="GlobeIcon"
        />
        {{ isEthiopianCalender ? 'ኢት': 'GR' }}
      </b-nav-item>
      <dark-Toggler class="d-none d-lg-block" />
      <UserDropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Ripple from 'vue-ripple-directive'
import Logo from '../../@core/layouts/components/Logo.vue'
// import AuthMixin from '@/mixins/authMixin'
import UserDropdown from '../../@core/layouts/components/app-navbar/components/UserDropdown.vue'
import DateLocalMixin from '@/mixins/dateLocalMixin'
import { dateLocalTypeField } from '@/api/field_model'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItem,
    // Navbar Components
    DarkToggler,
    UserDropdown,
    Logo,
  },
  directives: {
    Ripple,
  },
  mixins: [DateLocalMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  methods: {
    changeLocalLang() {
      this.$store.commit('auth/UPDATE_DATE_LOCAL_TYPE', this.isEthiopianCalender ? dateLocalTypeField.normal : dateLocalTypeField.ethiopian)
      if (this.isEthiopianCalender) {
        document.body.style.zoom = '95%'
      } else {
        document.body.style.zoom = '100%'
      }
    },
    goToHome() {
      if (this.$route.path !== '/') this.$router.push('/')
    },
    onSelectedBranch(v) {
      this.$router.push({
        name: 'sale',
        params: { branchId: v.id },
      })
      this.toggleVerticalMenuActive(false)
    },
  },
}
</script>
